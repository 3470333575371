<template>
<!-- 房贷进度估算 -->
    <div class="loanprogress">
        <div class="head">
            <div>
            <div class="title">贷款进度查询</div>
            <div class="title-icon" @click="goback"><van-icon name="arrow-left" /></div>
            </div>
            <div class="progress">
            <van-steps :active="active" active-color="white">
              <van-step>申请</van-step>
              <van-step>审批</van-step>
              <van-step>放款</van-step>
            </van-steps>
            </div>
            <div class="message">
                <div class="username">{{username}}</div>
                <div class="IDnumber">{{IDnumber}}</div>
            </div>
        <div class="middle">
            <div class="middle-box">无贷款记录</div>
        </div>
        </div>

        <div class="bottom">
            <img src="../../assets/images/loanprogress.png">
            <div class="bottom-box">查询不到购房记录</div>
        </div>
    </div>
</template>
<script>
export default {
    name:"LoanProgressQuery",
    data(){
        return{
            active: 0,
            username:"",
            IDnumber:"",
        };
    },
   methods: {
       goback(){
           this.$router.push("/my/index");
       }
   },
}
</script>
<style lang="less" scoped>
.head{
    height: 236px;
    background: linear-gradient(90deg, rgba(49, 200, 182, 1) 0%, rgba(49, 226, 205, 1) 100%);
}
.title{
    color: white;
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding-top: 8px;
}
.title-icon{
    font-size: 18px;
    color: white;
    position: absolute;
    top: 8px;
    left:5px;
}
.message{
    height:70px;
    .username{
        color:#FFFFFF;
        font-size: 21px;
        font-family: "微软雅黑";
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .IDnumber{
        color:#FFFFFF;
        font-size: 19px;
        font-family: "微软雅黑";
        display: flex;
        justify-content: center;
        margin-top: 13px
    }
}
.middle{
    width: 325px;
    height: 82px;
    color: #CCCCCC;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    margin-top: 25px;
    font-size: 25px;
    box-shadow: 0px 20px 21px #dddbdb;
    font-family: "微软雅黑";
}
.bottom{
    img{
        width:70px;
        height:70px;
        margin-left: 149px;
        margin-top: 73px;
    }
    .bottom-box{
        color:#CCCCCC;
        font-size: 19px;
        margin-left: 107px;
    }
}
/deep/.van-step__circle-container{
    background: transparent;
}

/deep/.van-steps {
    background: transparent;
}
/deep/.van-step__line{
    background:#028777 ;
}
/deep/.van-step__circle {
    background: #028777;
}
/deep/.van-steps__items{
    width: 80%;
    margin: 0 auto;
}
/deep/.van-step__title {
    font-size: 15px;
    
}   
</style>
